<div class="position-relative">
  <div>
    <app-flowchart
      (addActivity)="onAddActivity($event)"
      (deleteActivities)="deleteSelectedNodes($event)"
      (duplicateActivities)="duplicateSelectedNodes($event)"
      (nodeClicked)="setSelectedNode($event)"
      (pasteActivities)="duplicateSelectedNodes($event, true)"
      [flowchartType]="FlowchartType.MISSION"
      [nodes]="nodes"
    />
  </div>

  <div class="d-flex position-absolute top-0">
    <div class="bg-body d-flex flex-column">
      <span [ngStyle]="{ 'font-size': 'small' }" class="pt-1 ps-3 pe-1">Module: {{ moduleName }}</span>
      <span [ngStyle]="{ 'font-size': 'small' }" class="pb-1 ps-3 pe-1">Mission: {{ missionName }}</span>
    </div>

    <button (click)="openOverview()" [ngStyle]="{ 'font-size': 'small' }" class="btn btn-primary btn-sm">
      <i class="bi bi-info-circle"></i> Info
    </button>
  </div>

  <div class="position-absolute top-0 end-0">
    <div *ngIf="startActivityField" class="bg-body mb-1">
      <app-resource-selector-field [data]="startActivityField" />
    </div>

    <div class="float-end">
      <a
        *ngIf="environment.playGameUrl && missionInfo"
        [ngStyle]="{ 'font-size': 'small' }"
        class="btn btn-primary btn-sm me-1"
        href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfoIdentifier }}{{ moduleId ? '&module=' + moduleId : '' }}"
        target="_blank"
      >
        <i class="bi bi-play-circle"></i> Play from start
      </a>

      <a
        *ngIf="environment.playGameUrl && missionInfo"
        [class.disabled]="!activityInstanceUid"
        [ngStyle]="{ 'font-size': 'small' }"
        class="btn btn-primary btn-sm"
        href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfoIdentifier }}&activity={{ activityInstanceUid }}{{
          moduleId ? '&module=' + moduleId : ''
        }}"
        target="_blank"
      >
        <i class="bi bi-play-circle"></i> Play from current activity
      </a>
    </div>
  </div>

  <div *ngIf="!loadingMission && !loadingActivity" class="container mt-2 pb-2">
    <app-activity *ngIf="currentActivity" [uid]="currentActivity.dataInstanceUid" />

    <app-struct-instance-editor *ngIf="!currentActivity && missionInfo" [data]="missionInfo"></app-struct-instance-editor>
  </div>
</div>
