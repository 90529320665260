import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { AlertService } from '@services/UI-elements/alert-service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { debounce, fuzzySearch, Logger } from '@services/utils';
import { FileEndpoints } from '@services/api';
import { GeneratedFileMeta } from '@services/types/generated';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;
  @Input() fileType: string | undefined;
  @Input() currentURL = '';

  @Output() newFileSelected = new EventEmitter<{ name: string; url: string; mimeType: string }>();

  value!: string;

  currentFileMeta?: GeneratedFileMeta;
  fileName = '';
  file?: File;

  loadedFiles: GeneratedFileMeta[] = [];
  searchFiles: GeneratedFileMeta[] = [];
  loadingFiles = true;

  replaceFileUid?: string;
  uploadFileName = '';
  uploadFileAlt = '';

  uploading = false;
  editingName = false;

  searchTerm = '';

  fileSelectModalRef: NgbModalRef | undefined;
  debouncedSearch = debounce(this.search.bind(this), 250);

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    private fileEndpoints: FileEndpoints,
  ) {}

  search(event?: string) {
    const query = event ?? this.searchTerm;
    if (!query.length) {
      this.searchFiles = this.loadedFiles;
      return;
    }

    this.searchFiles = fuzzySearch(query, this.loadedFiles, 0.75, (f) => f.name);
  }

  async prepareUpload() {
    await this.loadMedia();
    this.file = undefined;
    this.replaceFileUid = undefined;
    this.fileName = '';
  }

  async prepareReplace() {
    await this.loadMedia();

    this.file = undefined;
    this.replaceFileUid = this.data.value;
    this.fileName = this.loadedFiles.find((f) => f.uid === this.data?.value)?.name || '';
  }

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.FILE_REF, this.data.value);
    if (this.data.value) await this.selectFile(this.data.value);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      if (this.data.value) await this.selectFile(this.data.value);
      else {
        this.fileName = '';
        this.currentFileMeta = undefined;
      }
    }
  }

  async update() {
    try {
      this.value = this.currentFileMeta?.uid || '';
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.uploadFileName = this.file.name;
    }
  }

  async onUpdateFileName() {
    if (!this.data) throw new Error('Data not found');

    try {
      this.uploading = true;
      const newFileMeta = new FormData();
      newFileMeta.append('name', this.uploadFileName);
      newFileMeta.append('alt', this.uploadFileAlt);
      this.currentFileMeta = await lastValueFrom(this.fileEndpoints.updateFileMeta(this.data.value, newFileMeta));
      await this.loadMedia();
      return;
    } finally {
      this.uploading = false;
      this.editingName = false;
    }
  }

  openSelectFileModal(content: TemplateRef<unknown>) {
    this.fileSelectModalRef = this.openModal(content);
  }

  openModal(content: TemplateRef<unknown>, modalDialogClass?: string): NgbModalRef {
    this.modalService.dismissAll('Closed before opening new modal');
    return this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title', modalDialogClass, size: 'lg' });
  }

  async submitFileUpload(modal: NgbModalRef) {
    if (!this.file) {
      if (!this.replaceFileUid) {
        console.warn('No file selected');
        return;
      }

      if (!this.uploadFileName) {
        window.alert('Please enter a name for the file');
        return;
      }

      try {
        this.uploading = true;
        const newFileMeta = new FormData();
        newFileMeta.append('name', this.uploadFileName);
        newFileMeta.append('alt', this.uploadFileAlt);
        this.currentFileMeta = await lastValueFrom(this.fileEndpoints.updateFileMeta(this.replaceFileUid, newFileMeta));
        await this.loadMedia();
        modal.dismiss('Name changed');
        return;
      } finally {
        this.uploading = false;
      }
    }

    try {
      this.uploading = true;

      const newFileMeta = new FormData();
      newFileMeta.append('file', this.file);
      newFileMeta.append('name', this.uploadFileName);
      newFileMeta.append('alt', this.uploadFileAlt);

      if (this.replaceFileUid) {
        this.currentFileMeta = await lastValueFrom(this.fileEndpoints.updateFileMeta(this.replaceFileUid, newFileMeta));
      } else {
        if (!this.file) {
          Logger.error('No file selected');
          return;
        }
        this.currentFileMeta = await this.fileEndpoints.uploadFile(newFileMeta);
      }

      this.newFileSelected.next({
        name: this.file.name,
        url: URL.createObjectURL(this.file),
        mimeType: this.file.type,
      });

      await this.loadMedia();
      await this.update();

      modal.dismiss('File uploaded');
    } finally {
      this.uploading = false;
    }
  }

  async loadMedia() {
    this.loadingFiles = true;
    const allFiles = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) => f.files);

    if (!this.fileType) {
      this.loadedFiles = allFiles;
    }

    switch (this.fileType) {
      case 'video':
        this.loadedFiles = allFiles.filter((file) => file.fileType.startsWith('video'));
        break;
      case 'audio':
        this.loadedFiles = allFiles.filter((file) => file.fileType.startsWith('audio'));
        break;
      case 'image':
        this.loadedFiles = allFiles.filter((file) => file.fileType.includes('image'));
        break;
    }

    this.search();
    this.loadingFiles = false;
  }

  async selectFile(fileUid: string) {
    this.currentFileMeta = await lastValueFrom(this.fileEndpoints.getFileMeta(fileUid));

    this.newFileSelected.next({
      name: this.currentFileMeta.name,
      url: this.currentFileMeta.url,
      mimeType: this.currentFileMeta.fileType,
    });

    this.value = fileUid;
    this.fileName = this.currentFileMeta.name;

    await this.update();

    this.fileSelectModalRef?.close(this.currentFileMeta.uid);
    this.clearPopovers();
  }

  /**
   * Removes the file from the data instance, but doesn't delete the file
   */
  async clearFile() {
    if (this.currentFileMeta) {
      try {
        this.currentFileMeta = undefined;

        switch (this.fileType) {
          case 'audio':
            this.newFileSelected.next({ name: '', url: '', mimeType: '' });
            break;
          case 'image':
            this.newFileSelected.next({ name: '', url: 'assets/images/select_image.png', mimeType: 'image/png' });
            break;
          case 'video':
            this.newFileSelected.next({ name: '', url: '', mimeType: 'video/mp4' });
            break;
        }

        this.alertService.showAlert('Cleared ' + this.data?.field.name, BootstrapClass.INFO);
        await this.update();
      } catch (error: unknown) {
        this.alertService.error('An error occurred: ' + (error as Error).message);
      }
    }
  }

  async deleteFile(fileUid: string, fileName: string) {
    if (confirm('Are you sure you want to delete ' + fileName + '?')) {
      try {
        await lastValueFrom(this.fileEndpoints.deleteFile(fileUid));
        this.loadedFiles = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) => f.files);

        if (this.currentFileMeta?.uid === fileUid) {
          this.currentFileMeta = undefined;
          this.currentURL = '';

          switch (this.fileType) {
            case 'image':
              this.newFileSelected.next({ name: '', url: 'assets/images/select_image.png', mimeType: 'image/png' });
              break;
            case 'audio':
              this.newFileSelected.next({ name: '', url: '', mimeType: '' });
              break;
            case 'video':
              this.newFileSelected.next({ name: '', url: '', mimeType: 'video/mp4' });
              break;
          }

          await this.update();
        }
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          this.alertService.error('This file is being used somewhere and cannot be deleted.');
        } else {
          // Handle other errors
          this.alertService.error('An error occurred: ' + (error as Error).message);
        }
      }
    }
  }

  clearPopovers() {
    setTimeout(() => {
      document.querySelectorAll('ngb-popover-window').forEach((popover) => {
        // Hack for now to delete the popover elements manually...
        popover.remove();
      });
    }, 800);
  }

  openReplaceModal(uploadModal: TemplateRef<unknown>) {
    this.prepareReplace().then(() => {
      this.openModal(uploadModal);
      const fileUpload = document.getElementById('fileUpload');
      if (fileUpload) fileUpload.click();
    });
  }
}
