import Konva from 'konva';
import { FieldValueForCanvasEditor } from '@services/types/FieldValueForCanvasEditor';
import { Vector2 } from '@services/utils';

export type DataInstanceFieldData<T> = {
  dataInstanceUid: string;
  fieldValue: FieldValueForCanvasEditor<T>;
};

export enum ShapeType {
  RECTANGLE = 'Rectangle',
  CIRCLE = 'Circle',
  PIN = 'Pin',
}

type Rectangle = {
  type: ShapeType.RECTANGLE;
  size: DataInstanceFieldData<Vector2>;
  media: DataInstanceFieldData<string>;
};

type Circle = {
  type: ShapeType.CIRCLE;
  radius: DataInstanceFieldData<number>;
  media: DataInstanceFieldData<string>;
};

type Pin = {
  type: ShapeType.PIN;
  position: DataInstanceFieldData<Vector2>;
  panelPosition: DataInstanceFieldData<Vector2>;
};

export type VisualTarget = (Rectangle | Circle | Pin) & {
  position: DataInstanceFieldData<Vector2>;
  pivot?: DataInstanceFieldData<Vector2>; // NOTE: pivot is optional for backwards compatibility
  konvaShape?: Konva.Shape[];
  konvaImage?: Konva.Image;
  konvaPivot?: Konva.Circle;
  name: string;
  isCorrect?: boolean;
  hideBorder: boolean;
  visible: boolean;
  locked: boolean;
  targetType: TargetType;
  uid: string;
};

export class PivotKonvaRect {
  public constructor(
    public readonly origin: Vector2,
    public readonly size: Vector2,
  ) {}

  public static fromShape(shape: Konva.Shape): PivotKonvaRect | undefined {
    if (shape instanceof Konva.Rect) {
      return new PivotKonvaRect(
        new Vector2({
          x: shape.x(),
          y: shape.y(),
        }),
        new Vector2({ x: shape.width(), y: shape.height() }),
      );
    }

    if (shape instanceof Konva.Circle) {
      return new PivotKonvaRect(
        new Vector2({ x: shape.x() - shape.radius(), y: shape.y() - shape.radius() }),
        new Vector2({ x: 2 * shape.radius(), y: 2 * shape.radius() }),
      );
    }

    return undefined;
  }

  public static fromTarget(target: VisualTarget, canvasSize: Vector2): PivotKonvaRect {
    switch (target.type) {
      case ShapeType.RECTANGLE: {
        const position = target.position.fieldValue.value;
        const size = target.size.fieldValue.value;
        return new PivotKonvaRect(
          new Vector2({
            x: position.x * canvasSize.x,
            y: position.y * canvasSize.y,
          }),
          new Vector2({ x: size.x * canvasSize.x, y: size.y * canvasSize.y }),
        );
      }

      case ShapeType.CIRCLE: {
        const position = target.position.fieldValue.value;
        const radius = target.radius.fieldValue.value;
        return new PivotKonvaRect(
          new Vector2({ x: (position.x - radius) * canvasSize.x, y: (position.y - radius) * canvasSize.y }),
          new Vector2({ x: 2 * radius * canvasSize.x, y: 2 * radius * canvasSize.y }),
        );
      }

      default:
        throw new Error(`Target type '${target.type}' is not supported`);
    }
  }

  public get center() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get bottomLeft() {
    return this.origin;
  }

  public get bottomCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y,
    });
  }

  public get bottomRight() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y,
    });
  }

  public get leftCenter() {
    return new Vector2({
      x: this.origin.x,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get topLeft() {
    return new Vector2({
      x: this.origin.x,
      y: this.origin.y + this.size.y,
    });
  }

  public get topCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x / 2,
      y: this.origin.y + this.size.y,
    });
  }

  public get topRight() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y + this.size.y,
    });
  }

  public get rightCenter() {
    return new Vector2({
      x: this.origin.x + this.size.x,
      y: this.origin.y + this.size.y / 2,
    });
  }

  public get points() {
    return [
      this.bottomCenter,
      this.bottomLeft,
      this.bottomRight,
      this.center,
      this.leftCenter,
      this.rightCenter,
      this.topCenter,
      this.topLeft,
      this.topRight,
    ];
  }
}

export type ContextMenuOptions = Record<
  string,
  {
    label: string;
    action: (x: number, y: number) => void;
  }[]
>;

export enum Orientation {
  Vertical,
  Horizontal,
}

export enum TargetType {
  CLICK_TARGET = 'ClickTarget',
  VISUAL_ELEMENT = 'VisualElement',
  DROP_TARGET = 'DropTarget',
  DRAGGABLE = 'Draggable',
  MAP_PIN = 'MapPin',
}

export type SnappingEdge = {
  guide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
};

export type LineStops = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  diff: number;
};

export type LineGuide = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  orientation: 'V' | 'H';
};
