import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { FieldType, FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-text-with-audio-field',
  templateUrl: './text-with-audio-field.component.html',
  styleUrl: './text-with-audio-field.component.scss',
})
export class TextWithAudioFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;
  currentResource?: DataInstance;

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private structRepository: StructTypeRepository,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
    if (!this.value) return;
    this.currentResource = await this.dataInstanceRepository.get(this.value);
    if (!this.currentResource.fieldValues['audio']) {
      const textWithAudioStruct = await this.structRepository.get('TextWithAudio');
      const audioField = textWithAudioStruct.fields['audio'];
      this.currentResource.fieldValues['audio'] = new FieldValue({
        field: audioField,
        value: '',
        dataInstanceUid: this.value,
      });
    }
  }

  async addStructInstance() {
    const dataInstance = await this.dataInstanceRepository.create('TextWithAudio');
    await this.data.set(dataInstance);

    this.currentResource = dataInstance;
  }
}
