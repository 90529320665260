<div
  [ngbPopover]="preview"
  [popoverContext]="{ file }"
  container="body"
  triggers="hover"
  style="display: grid; place-items: center"
  [style.width]="size + 'px'"
  [style.height]="size + 'px'"
>
  <img
    *ngIf="file.fileType.startsWith('image')"
    [src]="getImageUrlForWidth(file, size)"
    [alt]="file.alt"
    style="width: inherit; height: inherit; object-fit: contain"
  />
  <video *ngIf="file.fileType.startsWith('video')" [src]="file.url" style="width: inherit; height: inherit; object-fit: contain"></video>
  <i *ngIf="file.fileType.startsWith('audio')" class="bi bi-soundwave" style="object-fit: contain"></i>
</div>

<ng-template #preview let-file="file">
  <div [ngSwitch]="true">
    <ng-container *ngSwitchCase="file.fileType.startsWith('image')">
      <img [alt]="file.alt" [src]="getImageUrlForWidth(file, 200)" style="max-width: 200px; max-height: 200px; object-fit: contain" />
    </ng-container>

    <ng-container *ngSwitchCase="file.fileType.startsWith('video')">
      <video [src]="file.url" autoplay controls loop muted style="max-width: 200px; max-height: 200px; object-fit: contain"></video>
    </ng-container>

    <ng-container *ngSwitchCase="file.fileType.startsWith('audio')">
      <audio [src]="file.url" controls autoplay loop style="width: 150px"></audio>
    </ng-container>

    <ng-container *ngSwitchDefault>No preview available</ng-container>
  </div>
</ng-template>
