import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { AlertService } from '@services/UI-elements/alert-service';
import { Logger } from '@services/utils';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
})
export class ImageFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.IMAGE_REF, this.data.value);
  }

  async setFile(uid: string) {
    try {
      this.value = uid;
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
