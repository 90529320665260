import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-name',
  templateUrl: './file-name.component.html',
  styleUrl: './file-name.component.scss',
})
export class FileNameComponent {
  @Input({ required: true }) name!: string;
}
