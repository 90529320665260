import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextMenuOptions, ShapeType, TargetType, VisualTarget } from './canvas-editor.types';
import { Subscription } from 'rxjs';
import { DataInstance } from '@services/entities';
import { Logger, Vector2 } from '@services/utils';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { DataInstanceRepository, EnumTypeRepository, StructTypeRepository } from '@services/repositories';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-canvas-editor',
  templateUrl: './canvas-editor.component.html',
  styleUrls: ['./canvas-editor.component.scss'],
})
export class CanvasEditorComponent implements OnInit, OnDestroy, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value: string = '';

  dataInstance: DataInstance | undefined;
  canvasElements: DataInstance[] = [];
  visualTargets: Record<string, VisualTarget> = {};
  visualTargetNames: Record<string, string> = {};

  backgroundInstance?: DataInstance;
  canvasRatio: Vector2 = new Vector2([1, 1]);
  selected = '';
  selectedInstance?: DataInstance;
  newCanvasRatioWidth = '1';
  newCanvasRatioHeight = '1';
  backgroundImageSize?: Vector2;

  protected contextMenu: ContextMenuOptions = {};
  protected readonly Object = Object;
  private routeQueryParamSub?: Subscription;
  private routeParamSub?: Subscription;
  private instanceUpdatedSub?: Subscription;

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
    private enumTypeRepository: EnumTypeRepository,
    private loadingScreenService: LoadingScreenService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.data) {
      throw new Error('Data input is required');
    }
    await this.loadingScreenService.show(async () => {
      await this.loadEditor(this.data.dataInstanceUid);

      this.selectedInstance = undefined;
    });

    // To check if the type of background changes (eg. ImagePlaceableMedia to VideoPlaceableMedia)
    this.instanceUpdatedSub = this.dataInstanceRepository.entityUpdated$.subscribe(async (instance) => {
      if (instance && this.dataInstance && this.backgroundInstance && instance.randomIdentifier === this.dataInstance.randomIdentifier) {
        const newBackgroundFieldValue = instance.tryGetField('background');
        const oldBackgroundUid = await this.backgroundInstance?.identifier;
        if (newBackgroundFieldValue && newBackgroundFieldValue.value !== oldBackgroundUid) {
          await this.updateBackgroundInstance();
        }
      }
    });
  }

  ngOnDestroy() {
    this.routeQueryParamSub?.unsubscribe();
    this.routeParamSub?.unsubscribe();
    this.instanceUpdatedSub?.unsubscribe();
  }

  async addVisualElement(
    shapeType: 'Circle' | 'Rectangle',
    position = new Vector2([0.5, 0.5]),
    size = new Vector2([0.04, 0.06]),
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    const visualElementInstance = await this.dataInstanceRepository.create('VisualElement');
    await visualElementInstance.fieldValues['position']!.set(position);

    // The creation of a visual element makes a shape instance as well, but this might be the wrong shape
    const shapeFieldValue = visualElementInstance.fieldValues['shape']!;
    const shapeInstance = await this.dataInstanceRepository.get(shapeFieldValue.value);
    let shapeDefaultValue = shapeFieldValue.field.defaultValue;
    if (!shapeDefaultValue) shapeDefaultValue = (await this.enumTypeRepository.get('Shape')).options[0];
    if (shapeDefaultValue === shapeType) {
      if (shapeType === 'Circle') {
        await shapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await shapeInstance.fieldValues['size']!.set(size);
      }
    } else {
      // The default shape is not the wanted one, so we have to create it manually
      const newShapeInstance = await this.dataInstanceRepository.create(shapeType);
      if (shapeType === 'Circle') {
        await newShapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await newShapeInstance.fieldValues['size']!.set(size);
      }
      await visualElementInstance.fieldValues['shape']!.set(newShapeInstance);
      await this.dataInstanceRepository.delete(shapeInstance, true);
    }

    const fieldValue = this.dataInstance.fieldValues['visualElements']!;
    await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await visualElementInstance.identifier]);

    this.canvasElements.push(visualElementInstance);
    this.visualTargetNames[visualElementInstance.randomIdentifier] = await visualElementInstance.identifier;
    await this.setVisualTargets();
  }

  async addMapPinLocation(position = new Vector2([0.5, 0.5]), panelPosition = new Vector2([0.5, 0.5])) {
    if (!this.dataInstance) return;

    const instance = await this.dataInstanceRepository.create('MapPinLocation');
    await instance.fieldValues['position']!.set(position);
    await instance.fieldValues['panelPosition']!.set(panelPosition);

    const fieldValue = this.dataInstance.fieldValues['mapLocations'];
    if (fieldValue) await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instance.identifier]);
    else await this.dataInstance.setFieldValue('mapLocations', [await instance.identifier]);

    this.canvasElements.push(instance);
    this.visualTargetNames[instance.randomIdentifier] = (await instance.identifier) as string;
    await this.setVisualTargets();
  }

  async addInstance(
    structType: 'Draggable' | 'DropPoint' | 'DropArea' | 'ClickTarget',
    shapeType: 'Rectangle' | 'Circle',
    position = new Vector2([0.5, 0.5]),
    size = new Vector2([0.04, 0.06]),
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    switch (structType) {
      case 'Draggable': {
        if (!Object.keys(this.dataInstance.fieldValues).includes('draggables')) return;
        break;
      }
      case 'DropPoint':
      case 'DropArea':
      case 'ClickTarget': {
        if (!Object.keys(this.dataInstance.fieldValues).includes('targets')) return;
        break;
      }
      default:
        return;
    }

    const instanceStruct = await this.dataInstanceRepository.create(structType);

    const visualElementInstanceUid = instanceStruct.tryGetField('visualElement');
    if (!visualElementInstanceUid) {
      Logger.warn('No visual element found for instance ' + instanceStruct.__uid);
      return;
    }

    const visualElementInstance = await this.dataInstanceRepository.get(visualElementInstanceUid.value as string);
    await visualElementInstance.fieldValues['position']!.set(position);

    // The creation of a visual element makes a shape instance as well, but this might be the wrong shape
    const shapeFieldValue = visualElementInstance.fieldValues['shape']!;
    const shapeInstance = await this.dataInstanceRepository.get(shapeFieldValue.value);
    let shapeDefaultValue = shapeFieldValue.field.defaultValue;
    if (!shapeDefaultValue) shapeDefaultValue = (await this.enumTypeRepository.get('Shape')).options[0];
    if (shapeDefaultValue === shapeType) {
      if (shapeType === 'Circle') {
        await shapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await shapeInstance.fieldValues['size']!.set(size);
      }
    } else {
      // The default shape is not the wanted one, so we have to create it manually
      const newShapeInstance = await this.dataInstanceRepository.create(shapeType);
      if (shapeType === 'Circle') {
        await newShapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await newShapeInstance.fieldValues['size']!.set(size);
      }
      await visualElementInstance.fieldValues['shape']!.set(newShapeInstance);
      await this.dataInstanceRepository.delete(shapeInstance, true);
    }

    switch (structType) {
      case 'Draggable': {
        const fieldValue = this.dataInstance.tryGetField('draggables');
        if (!fieldValue) throw new Error('Field draggables not found in activity' + this.dataInstance.__uid);
        await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instanceStruct.identifier]);
        break;
      }
      case 'DropPoint':
      case 'DropArea':
      case 'ClickTarget': {
        const fieldValue = this.dataInstance.tryGetField('targets');
        if (!fieldValue) throw new Error('Field targets not found in activity' + this.dataInstance.__uid);
        await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instanceStruct.identifier]);
        break;
      }
      default:
        return;
    }

    this.canvasElements.push(instanceStruct);
    this.visualTargetNames[instanceStruct.randomIdentifier] = (await instanceStruct.identifier) as string;
    await this.setVisualTargets();
  }

  // async addPreset(preset: Preset) {
  //   if (!this.dataInstance || !preset) return;
  //
  //   for (const shape of preset.shapes) {
  //     if (shape.isVisualElement) {
  //       if (shape.type === 'Circle') {
  //         await this.addVisualElement(shape.type, new Vector2([shape.x, shape.y]), undefined, shape.radius);
  //       } else {
  //         await this.addVisualElement(
  //           shape.type,
  //           new Vector2([shape.x, shape.y]),
  //           new Vector2([shape.size?.width ?? 0.04, shape.size?.height ?? 0.06]),
  //         );
  //       }
  //     } else {
  //       if (shape.type === 'Circle') {
  //         await this.addInstance('ClickTarget', shape.type, new Vector2([shape.x, shape.y]), undefined, shape.radius);
  //       } else {
  //         await this.addInstance(
  //           'ClickTarget',
  //           shape.type,
  //           new Vector2([shape.x, shape.y]),
  //           new Vector2([shape.size?.width ?? 0.04, shape.size?.height ?? 0.06]),
  //         );
  //       }
  //     }
  //   }
  // }

  async getInstances() {
    if (!this.dataInstance) return;

    for (const fieldType of ['draggables', 'targets', 'visualElements', 'mapLocations']) {
      const fieldValue = this.dataInstance.tryGetField(fieldType);
      if (!fieldValue) continue;
      const uids = fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value) as string[];

      const instances = (await Promise.all(
        uids.map(async (uid) => {
          const instance = await this.dataInstanceRepository.get(uid);
          const nameField = (await this.structTypeRepository.get(instance.dataType)).fields['name'];

          if (nameField) {
            let fieldValue = instance.tryGetField('name')?.value;
            if (!fieldValue) fieldValue = await instance.identifier;

            this.visualTargetNames[instance.randomIdentifier] = fieldValue as string;
          } else {
            this.visualTargetNames[instance.randomIdentifier] = (await instance.identifier) as string;
          }

          return instance;
        }),
      )) as DataInstance[];

      this.canvasElements.push(...instances);
    }

    await this.setVisualTargets();
  }

  async setVisualTargets() {
    const vTargets = { ...this.visualTargets };

    const currentInstanceUids = await Promise.all(this.canvasElements.map((instance) => instance.randomIdentifier));

    for (const uid in this.visualTargets) {
      if (!currentInstanceUids.includes(uid)) {
        delete vTargets[uid];
      }
    }

    for (const instance of this.canvasElements) {
      // Add the visual targets that are new
      if (!Object.prototype.hasOwnProperty.call(vTargets, instance.randomIdentifier)) {
        let visualElementInstance = undefined;
        let targetType = TargetType.DROP_TARGET;
        let hideBorder = false;
        let isCorrect = false;

        switch (instance.dataType) {
          case 'VisualElement':
            visualElementInstance = instance;
            targetType = TargetType.VISUAL_ELEMENT;
            break;
          case 'ClickTarget':
            targetType = TargetType.CLICK_TARGET;
            break;
          case 'MapPinLocation':
            targetType = TargetType.MAP_PIN;
            break;
          case 'Draggable':
            targetType = TargetType.DRAGGABLE;
            break;
        }

        if (instance.dataType !== 'VisualElement' && instance.dataType !== 'MapPinLocation') {
          const visualElementField = instance.tryGetField('visualElement');
          if (!visualElementField) {
            Logger.warn('No visual element found for instance ' + instance.__uid);
            continue;
          }

          visualElementInstance = await this.dataInstanceRepository.get(visualElementField.value as string);

          if (!visualElementInstance) {
            Logger.warn('Visual element not found for instance ' + instance.__uid);
            continue;
          }

          const isCorrectField = instance.tryGetField('isCorrect');
          isCorrect = isCorrectField?.getDeserializedValue(FieldType.BOOLEAN, isCorrectField.value) ?? false;

          const hideBorderField = instance.tryGetField('hideInteractableIndicator');
          hideBorder = hideBorderField?.getDeserializedValue(FieldType.BOOLEAN, hideBorderField.value) ?? false;
        }

        switch (instance.dataType) {
          case 'MapPinLocation': {
            const positionFieldValue = instance.tryGetField('position');
            const position =
              positionFieldValue?.getDeserializedValue(FieldType.VECTOR2, positionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const panelPositionFieldValue = instance.tryGetField('panelPosition');
            const panelPosition =
              panelPositionFieldValue?.getDeserializedValue(FieldType.VECTOR2, panelPositionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            // NOTE: we need to explicitly check if the field exists, because it is wrapped in a proxy which throws an error if the field does not exist (instead of returning undefined)
            const pivotFieldValue = instance.tryGetField('pivot');
            const pivot = pivotFieldValue?.getDeserializedValue(FieldType.VECTOR2, pivotFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const identifier = await instance.identifier;
            vTargets[instance.randomIdentifier] = {
              type: ShapeType.PIN,
              position: { dataInstanceUid: identifier, fieldValue: { field: 'position', value: position } },
              ...(instance.hasField('pivot') ? { dataInstanceUid: identifier, fieldValue: { field: 'pivot', value: pivot } } : {}),
              panelPosition: {
                dataInstanceUid: identifier,
                fieldValue: { field: 'panelPosition', value: panelPosition },
              },
              targetType: targetType,
              uid: instance.randomIdentifier,
              name: this.visualTargetNames[instance.randomIdentifier],
              visible: true,
              locked: false,
            } as VisualTarget; // todo: fix this type (tip: use 'satisfies' instead)

            break;
          }

          default: {
            if (!visualElementInstance) return;

            const shapeInstanceField = visualElementInstance.tryGetField('shape');
            if (!shapeInstanceField) {
              Logger.warn('VisualElement instance does not have a shape');
              return;
            }

            const shapeInstance = await this.dataInstanceRepository.get(shapeInstanceField.value as string);
            const shapeType = shapeInstance.dataType as 'Circle' | 'Rectangle';

            const positionFieldValue = visualElementInstance.tryGetField('position');
            const position =
              positionFieldValue?.getDeserializedValue(FieldType.VECTOR2, positionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            // NOTE: we need to explicitly check if the field exists, because it is wrapped in a proxy which throws an error if the field does not exist (instead of returning undefined)
            const pivotFieldValue = visualElementInstance.tryGetField('pivot');
            const pivot = pivotFieldValue?.getDeserializedValue(FieldType.VECTOR2, pivotFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const sizeField = shapeInstance.tryGetField('size');
            const size = sizeField?.getDeserializedValue(FieldType.VECTOR2, sizeField.value) ?? new Vector2([0.04, 0.06]);

            const radiusField = shapeInstance.tryGetField('radius');
            const radius = radiusField?.getDeserializedValue(FieldType.FLOAT, radiusField.value) ?? 0.04;

            let media = visualElementInstance.tryGetField('media');
            const placeableMedia = media ? await this.dataInstanceRepository.get(media.value) : undefined;

            if (media) {
              if (!placeableMedia) {
                Logger.warn('Placeable media not found for instance ' + media);
                continue;
              }

              const imageFieldValue = placeableMedia.tryGetField('image');

              if (imageFieldValue) {
                media = imageFieldValue;
              } else {
                media = null;
              }
            }

            const instanceIdentifier = instance.randomIdentifier;

            vTargets[instanceIdentifier] = {
              type: shapeType,
              position: {
                dataInstanceUid: await visualElementInstance.identifier,
                fieldValue: { field: 'position', value: position },
              },
              ...(visualElementInstance.hasField('pivot')
                ? {
                    pivot: {
                      dataInstanceUid: await visualElementInstance.identifier,
                      fieldValue: { field: 'pivot', value: pivot },
                    },
                  }
                : {}),
              size: {
                dataInstanceUid: await shapeInstance.identifier,
                fieldValue: { field: 'size', value: size },
              },
              radius: {
                dataInstanceUid: await shapeInstance.identifier,
                fieldValue: { field: 'radius', value: radius },
              },
              media: {
                dataInstanceUid: await placeableMedia?.identifier,
                fieldValue: { field: 'media', value: media?.value ?? '' },
              },
              targetType: targetType,
              isCorrect: isCorrect,
              hideBorder: hideBorder,
              visible: true,
              locked: false,
              uid: instanceIdentifier,
              name: this.visualTargetNames[instanceIdentifier],
            } as VisualTarget; // todo: fix this type (tip: use 'satisfies' instead)

            break;
          }
        }
      }
    }

    this.visualTargets = vTargets;
  }

  onUpdateName(update: { instance: DataInstance; name: string }) {
    this.visualTargetNames[update.instance.randomIdentifier] = update.name;
  }

  onShapeSelected(name: string) {
    const selectedInstanceUid = Object.entries(this.visualTargetNames).find(([_, value]) => value === name)?.[0] ?? '';
    this.selectedInstance = this.canvasElements.find((instance) => instance.randomIdentifier === selectedInstanceUid);
    this.selected = name;
  }

  onResetSelected() {
    this.selectedInstance = undefined;
    this.selected = '';
  }

  onBackgroundImageSizeChange(size?: Vector2) {
    this.backgroundImageSize = size;
  }

  onInstanceSelected(instance: DataInstance | undefined) {
    this.selectedInstance = instance;
    this.selected = instance ? this.visualTargetNames[instance.randomIdentifier] : '';
  }

  onToggleHideInstance(instance: DataInstance) {
    this.visualTargets[instance.randomIdentifier].visible = !this.visualTargets[instance.randomIdentifier].visible;
    // trigger change detection
    this.visualTargets = { ...this.visualTargets };
  }

  onToggleLockInstance(instance: DataInstance) {
    this.visualTargets[instance.randomIdentifier].locked = !this.visualTargets[instance.randomIdentifier].locked;
    // trigger change detection
    this.visualTargets = { ...this.visualTargets };
  }

  async adjustCanvasRatio() {
    const width = +this.newCanvasRatioWidth;
    const height = +this.newCanvasRatioHeight;

    if (isNaN(width) || isNaN(height)) {
      console.error('The type of the canvasRatio input must be NUMBER, NUMBER');
      return;
    }
    this.canvasRatio = new Vector2({ x: width, y: height });
  }

  async adjustCanvasToBackground() {
    if (!this.backgroundImageSize) throw new Error('No background image, so impossible to scale');

    const { width, height } = environment.aspectRatio;
    const ratio = height / width;
    const canvasSize = ratio > 1 ? new Vector2({ x: 450, y: 800 }) : new Vector2({ x: 800, y: 450 });

    if (canvasSize.x / this.backgroundImageSize.x < canvasSize.y / this.backgroundImageSize.y) {
      const scaleWidth =
        Math.round((((canvasSize.y / this.backgroundImageSize.y) * this.backgroundImageSize.x) / canvasSize.x) * 100) / 100;
      this.newCanvasRatioWidth = scaleWidth.toString();
      this.newCanvasRatioHeight = '1';
    } else {
      const scaleHeight =
        Math.round((((canvasSize.x / this.backgroundImageSize.x) * this.backgroundImageSize.y) / canvasSize.y) * 100) / 100;
      this.newCanvasRatioWidth = '1';
      this.newCanvasRatioHeight = scaleHeight.toString();
    }

    await this.adjustCanvasRatio();
  }

  async duplicateInstance(instance: DataInstance, updateSelection?: boolean) {
    if (!this.dataInstance) throw new Error('No data instance found');

    const duplicatedInstance = await this.dataInstanceRepository.duplicateDataInstances({
      instanceUids: [await instance.identifier],
      dropExternalReferencesOfTypes: [],
    });

    const createdEl = duplicatedInstance.dataInstances[0];
    const createdElId = await createdEl.identifier;

    const field = this.getFieldForInstance(instance);
    const elementsFieldValue = this.dataInstance.fieldValues[field];
    await elementsFieldValue?.set([...elementsFieldValue.getDeserializedValue(FieldType.LIST, elementsFieldValue.value), createdElId]);

    this.canvasElements.push(createdEl);
    this.visualTargetNames[createdEl.randomIdentifier] = createdElId;

    await this.setVisualTargets();

    if (updateSelection) {
      this.onInstanceSelected(createdEl);
    }
  }

  async deleteInstance(instance: DataInstance) {
    if (!this.dataInstance) return;

    const wasSelected = this.selectedInstance === instance;

    const field = this.getFieldForInstance(instance);
    if (!Object.keys(this.dataInstance.fieldValues).includes(field)) return;

    const fieldValue = this.dataInstance.fieldValues[field];
    if (!fieldValue) {
      Logger.warn('Field not found');
      return;
    }

    const identifier = await instance.identifier;
    await fieldValue.set(fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value).filter((uid) => uid !== identifier));
    this.canvasElements.splice(this.canvasElements.indexOf(instance), 1);
    await this.dataInstanceRepository.delete(instance, true);
    await this.setVisualTargets();

    if (wasSelected) {
      this.onInstanceSelected(undefined);
    }
  }

  private getFieldForInstance(instance: DataInstance) {
    switch (instance.dataType) {
      case 'Draggable':
        return 'draggables';
      case 'DropPoint':
      case 'DropArea':
      case 'ClickTarget':
        return 'targets';
      case 'VisualElement':
        return 'visualElements';
      case 'MapPinLocation':
        return 'mapLocations';
    }

    throw new Error(`Instance type '${instance.dataType}' not found`);
  }

  private async updateBackgroundInstance() {
    if (!this.dataInstance) return;

    const backgroundFieldValue = this.dataInstance.tryGetField('background');
    if (!backgroundFieldValue) {
      Logger.warn('No background field found');
      return;
    }

    this.backgroundImageSize = undefined;

    if (this.selectedInstance === this.backgroundInstance) {
      this.backgroundInstance = await this.dataInstanceRepository.get(backgroundFieldValue.value);
      this.selectedInstance = this.backgroundInstance;
    } else {
      this.backgroundInstance = await this.dataInstanceRepository.get(backgroundFieldValue.value);
    }
  }

  private async loadEditor(activityInstanceUid: string) {
    Logger.debug(`Loading editor for ${activityInstanceUid}`);

    this.dataInstance = await this.dataInstanceRepository.get(activityInstanceUid);

    await this.updateBackgroundInstance();
    if (!this.backgroundInstance) {
      Logger.warn('No background instance found');
      return;
    }

    if (Object.keys(this.dataInstance.fieldValues).includes('canvasRatio')) {
      const canvasRatioFieldValue = this.dataInstance.fieldValues['canvasRatio']!;

      this.canvasRatio = canvasRatioFieldValue.getDeserializedValue(FieldType.VECTOR2, canvasRatioFieldValue.value);
      if (this.canvasRatio.x === 0 || this.canvasRatio.y === 0) {
        this.canvasRatio = new Vector2([1, 1]);
      }
    }

    await this.getInstances();

    switch (this.dataInstance.dataType) {
      case 'DragAndDropActivity': {
        this.contextMenu = {
          'Draggable objects': [
            {
              label: 'Rectangle draggable',
              action: (x: number, y: number) => this.addInstance('Draggable', 'Rectangle', new Vector2({ x, y })),
            },
          ],
          'Drop targets': [
            {
              label: 'Rectangle drop point',
              action: (x: number, y: number) => this.addInstance('DropPoint', 'Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Rectangle drop area',
              action: (x: number, y: number) => this.addInstance('DropArea', 'Rectangle', new Vector2({ x, y })),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
        };
        break;
      }
      case 'ClickActivity': {
        this.contextMenu = {
          Targets: [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Circle', new Vector2({ x, y })),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
        };
        break;
      }
      case 'Map': {
        this.contextMenu = {
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
        };
        break;
      }
    }
  }
}
