import { CompareOperator } from './CompareOperator';

export enum VariableType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Color = 'Color',
  Image = 'Image',
}

export abstract class AVariableType<T> {
  abstract getDefault(): T;
  abstract getValidOperators(): CompareOperator[];
  abstract serialize(value: T): string;
  abstract deserialize(value: string): T;
}

class StringVariableType extends AVariableType<string> {
  override getDefault(): string {
    return '';
  }

  override getValidOperators(): CompareOperator[] {
    return [CompareOperator.EqualTo, CompareOperator.NotEqualTo];
  }

  override serialize(value: string): string {
    return value;
  }

  override deserialize(value: string): string {
    return value;
  }
}

class NumberVariableType extends AVariableType<number> {
  override getDefault(): number {
    return 0;
  }

  override getValidOperators(): CompareOperator[] {
    return [
      CompareOperator.EqualTo,
      CompareOperator.NotEqualTo,
      CompareOperator.SmallerThan,
      CompareOperator.GreaterThan,
      CompareOperator.SmallerOrEqualTo,
      CompareOperator.GreaterOrEqualTo,
    ];
  }

  override serialize(value: number): string {
    return `${value}`;
  }

  override deserialize(value: string): number {
    return parseFloat(value);
  }
}

class BooleanVariableType extends AVariableType<boolean> {
  override getDefault(): boolean {
    return false;
  }

  override getValidOperators(): CompareOperator[] {
    return [CompareOperator.EqualTo, CompareOperator.NotEqualTo];
  }

  override serialize(value: boolean): string {
    return `${value}`;
  }

  override deserialize(value: string): boolean {
    return value === 'true';
  }
}

class ColorVariableType extends AVariableType<string> {
  override getDefault(): string {
    return '#00000000';
  }

  override getValidOperators(): CompareOperator[] {
    return [CompareOperator.EqualTo, CompareOperator.NotEqualTo];
  }

  override serialize(value: string): string {
    return value;
  }

  override deserialize(value: string): string {
    return value;
  }
}

class ImageVariableType extends AVariableType<string> {
  override getDefault(): string {
    return '';
  }

  override getValidOperators(): CompareOperator[] {
    return [CompareOperator.EqualTo, CompareOperator.NotEqualTo];
  }

  override serialize(value: string): string {
    return value;
  }

  override deserialize(value: string): string {
    return value;
  }
}

export const variableTypes = {
  [VariableType.Boolean]: new BooleanVariableType(),
  [VariableType.Color]: new ColorVariableType(),
  [VariableType.Image]: new ImageVariableType(),
  [VariableType.Number]: new NumberVariableType(),
  [VariableType.String]: new StringVariableType(),
};

export function getVariableType(type: VariableType) {
  return variableTypes[type];
}
