<div (cdkDropListDropped)="onMoveDataInstanceOrder($event)" cdkDropList>
  <div *ngFor="let instance of instances; let i = index" cdkDrag class="inline-list-item">
    <app-variable-comparison-field
      (deleteVariableComparison)="deleteInstance(instance)"
      *ngIf="instance.dataType === 'VariableComparison'"
      [instance]="instance"
      [showDelete]="true"
    />

    <app-set-variable-field
      (deleteSetVariable)="deleteInstance(instance)"
      *ngIf="instance.dataType === 'SetVariableAction'"
      [instance]="instance"
    />

    <app-struct-instance-editor
      (deleteStructInstance)="deleteInstance(instance)"
      *ngIf="instance.dataType !== 'VariableComparison' && instance.dataType !== 'SetVariableAction'"
      [data]="instance"
      [showDeleteButton]="true"
      [startCollapsed]="collapsedStates[i]"
    />

    <div cdkDragHandle class="btn second-header-button" id="draggable-list-handle">
      <i class="bi bi-arrows-move"></i>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center gap-1 my-1">
  <!--  Collapse/Expand all button-->
  <button
    (click)="toggleCollapseStates()"
    *ngIf="instances.length > 1"
    [ngbPopover]="allCollapsed ? 'Expand all' : 'Collapse all'"
    class="btn"
    triggers="hover"
  >
    <i [ngClass]="allCollapsed ? 'bi bi-chevron-expand' : 'bi bi-chevron-contract'"></i>
  </button>
  <!--  If we have few choices, show buttons-->
  <div *ngIf="structTypes.length <= 3" class="d-flex gap-1">
    <ng-container *ngFor="let structType of structTypes; let i = index">
      <button (click)="addInstance(structType.typeId)" class="btn btn-primary" id="add-list-{{ structType.typeId }}-button">
        <i class="bi bi-plus-circle me-1"></i> Add {{ structType.name }}
      </button>
    </ng-container>
  </div>
  <!--  If we have many choices, show a dropdown-->
  <div *ngIf="structTypes.length > 3">
    <select (change)="onStructTypeSelected($event)" class="form-select">
      <option disabled selected value="">Add {{ typeName }}...</option>
      <option *ngFor="let structType of structTypes" [value]="structType.typeId">{{ structType.name }}</option>
    </select>
  </div>
</div>
