<button (click)="addStructInstance()" *ngIf="this.data && !this.value" class="btn btn-primary ms-2">Add</button>

<div *ngIf="currentResource">
  <app-struct-instance-editor
    (deleteStructInstance)="deleteStructInstance.emit($event)"
    [data]="currentResource"
    [isSeamlessInline]="isSeamlessInline"
    [resourceStructType]="resourceStructType"
    [showDeleteButton]="showDeleteButton"
    [showHeader]="editorType !== 'SeamlessInline'"
    [showMedia]="showMedia"
    [titleOverride]="titleOverride"
  />
</div>
