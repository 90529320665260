<select
  (change)="update()"
  *ngIf="data"
  [(ngModel)]="currentChoice"
  [ngbPopover]="descriptions[currentChoice ?? '']"
  class="form-select card-header"
  id="enum-type-selector"
  name="dropdown"
  triggers="hover"
>
  <option *ngFor="let choice of choices" [value]="choice.value">🔁 {{ choice.name | prettifyName }}</option>
</select>

<app-variable-comparison-field
  *ngIf="data && currentChoiceDataInstance && currentChoice === 'VariableComparison'"
  [instance]="currentChoiceDataInstance"
/>

<app-set-variable-field
  *ngIf="data && currentChoiceDataInstance && currentChoice === 'SetVariableAction'"
  [instance]="currentChoiceDataInstance"
/>

<app-struct-instance-editor
  *ngIf="data && currentChoiceDataInstance && currentChoice !== 'VariableComparison' && currentChoice !== 'SetVariableAction'"
  [data]="currentChoiceDataInstance"
  [showHeader]="false"
/>
