export * from './Cache';
export * from './Color';
export * from './Dummy';
export * from './Hash';
export * from './Logger';
export * from './Try';
export * from './Vectors';

export function deepCopy<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as T;
  }

  const copiedObject: Partial<T> = {} as Partial<T>;
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      copiedObject[key] = deepCopy(obj[key]);
    }
  }

  return copiedObject as T;
}

export const instant = async <T>(method: (...args: unknown[]) => T | Promise<T>) => {
  return new Promise<T>((resolve) => {
    setTimeout(async () => resolve(await method()), 0);
  });
};

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// noinspection SpellCheckingInspection
export function generateRandomString(
  length: number,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(func: T, timeout = 300): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function prettifyName(input: string | undefined): string {
  return addSpacesBeforeUpperChars(input);
}

export function formatBytes(bytes: number) {
  if (bytes >= 1024 ** 3 / 2) return `${(bytes / 1024 ** 3).toFixed(1)} GB`;
  if (bytes >= 1024 ** 2 / 2) return `${(bytes / 1024 ** 2).toFixed(1)} MB`;
  if (bytes >= 1024 / 2) return `${(bytes / 1024).toFixed(1)} KB`;
  return `${bytes} B`;
}

export function addSpacesBeforeUpperChars(input: string | undefined): string {
  if (!input) return '';
  return input
    .split('')
    .map((char, index) => {
      if (char === char.toUpperCase() && char !== char.toLowerCase() && index !== 0) {
        return ' ' + char;
      }
      return char;
    })
    .join('');
}

export function replaceBackticksWithElement(input: string, element: string = 'span'): string {
  let isOpening = true;
  return input.replace(/`/g, () => {
    const spanTag = isOpening ? `<${element}>` : `</${element}>`;
    isOpening = !isOpening;
    return spanTag;
  });
}

export type Pair<T, K> = [T, K];

export const asyncFilter = async <T>(arr: T[], predicate: (v: T, index: number) => Promise<boolean>) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.filter((_v, index) => results[index]);
};

export const asyncFind = async <T>(arr: T[], predicate: (v: T, index: number) => Promise<boolean>) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.find((_v, index) => results[index]);
};

export function fuzzySearch<T>(query: string, list: T[], threshold: number = 0.5, identifier: (item: T) => string): T[] {
  const normalizedQuery = new Set(query.trim().toLowerCase().replace(/_/g, ' ').split(/(\s+)/));

  const scoreMatch = (item: string): number => {
    let score = 0;
    const lowerItem = item.toLowerCase().replace(/_/g, ' ');

    for (const queryWord of normalizedQuery) {
      if (lowerItem.includes(queryWord)) {
        score++;
      }
    }

    return score / normalizedQuery.size;
  };

  return list
    .map((item) => ({ item, score: scoreMatch(identifier(item)) }))
    .filter((entry) => entry.score >= threshold)
    .sort((a, b) => b.score - a.score)
    .map((entry) => entry.item);
}
