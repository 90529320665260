<div style="display: flex; gap: 0.5em; align-items: center">
  <div>
    <app-file-preview [size]="64" *ngIf="file" [file]="file" style="display: block; background-color: var(--bs-secondary-bg)" />
    <div *ngIf="!file" style="width: 64px; height: 64px; background-color: var(--bs-secondary-bg)"></div>
  </div>
  <div *ngIf="file">
    <div>
      <app-file-name [name]="file.name" />
    </div>
    <div class="text-muted">{{ file.fileSize | formatBytes }}</div>
    <div *ngIf="file.width && file.height" class="text-muted">{{ file.width }} x {{ file.height }}</div>
    <div *ngIf="file.fileType.startsWith('audio/')">
      <audio [src]="file.url" controls style="height: 1lh"></audio>
    </div>
  </div>
</div>
