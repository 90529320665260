import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '@services/utils';

@Pipe({
  name: 'formatBytes',
  standalone: true,
})
export class FormatBytesPipe implements PipeTransform {
  transform(value: number): string {
    return formatBytes(value);
  }
}
