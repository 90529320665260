<input
  (colorPickerSelect)="update($event)"
  [colorPicker]="data.value"
  [cpAlphaChannel]="'always'"
  [cpEyeDropper]="true"
  [cpOKButtonText]="'Save'"
  [cpOKButton]="true"
  [cpOutputFormat]="'hex'"
  [style.background]="data.value"
  [value]="data.value"
  class="form-control ms-2"
  id="{{ data.field.fieldId }}-color-picker"
  style="width: 7rem"
/>
