<div
  #scrollContainer
  [ngStyle]="{
    width: (canvasOrientation === Orientation.Horizontal ? '800' : '450') + 'px',
    height: (canvasOrientation === Orientation.Horizontal ? '450' : '800') + 'px',
  }"
  class="outline mx-auto overflow-auto"
  id="visual-editor-wrapper"
>
  <div #stageParent class="position-relative z-0 mx-auto overflow-hidden">
    <div #stageContainer class="z-3" id="visual-editor"></div>

    <video
      #backgroundVideo
      *ngIf="currentBackgroundVideoUrl"
      [src]="currentBackgroundVideoUrl"
      autoplay
      class="position-absolute top-0 w-100 h-100 object-fit-contain"
      loop
      muted
      style="z-index: -1"
    ></video>
  </div>

  <div *ngIf="editMenuSubject" id="target-menu">
    <div *ngFor="let field of editMenuSubject | menuSubject | async" class="field">
      <label for="input-{{ input.value }}">{{ field.label }}</label>
      <input #input (change)="field.set(input.value)" [value]="field.value" id="input-{{ input.value }}" />
    </div>
  </div>

  <div
    *ngIf="contextMenuOptions"
    [hidden]="!showContextMenu"
    [style.left.px]="showContextMenu?.x"
    [style.top.px]="showContextMenu?.y"
    id="context-menu"
  >
    <div id="close-btn">
      <button (click)="showContextMenu = undefined">x</button>
    </div>

    <div *ngFor="let category of contextMenuOptions | keyvalue" class="category">
      <div class="cat-label">{{ category.key }}</div>

      <div *ngFor="let option of category.value" class="option">
        <button (click)="runContextMenuAction(option.action)">{{ option.label }}</button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="backgroundInstance.dataType === 'VideoPlaceableMedia'">
  <div *ngIf="backgroundVideo?.nativeElement" [style]="{ width: 'fit-content' }" class="mx-auto mt-2 d-flex align-items-center gap-2">
    <button (click)="backgroundVideo?.nativeElement?.play()" *ngIf="backgroundVideo?.nativeElement?.paused" class="btn-outline-primary btn">
      <i class="fas fa-play pe-1"></i>
      Play background video
    </button>

    <button
      (click)="backgroundVideo?.nativeElement?.pause()"
      *ngIf="!backgroundVideo?.nativeElement?.paused"
      class="btn-outline-primary btn"
    >
      <i class="fas fa-pause pe-1"></i>
      Pause background video
    </button>

    <button
      (click)="backgroundVideo.nativeElement.currentTime = 0"
      *ngIf="backgroundVideo && backgroundVideo.nativeElement"
      class="btn-outline-primary btn"
    >
      <i class="fas fa-redo pe-1"></i>
      Restart background video
    </button>
  </div>
</ng-container>

<div class="d-flex align-items-center flex-wrap gap-2 ms-2 justify-content-between mx-2">
  <!--  <div *ngIf="activityInstance && activityInstance.dataType === 'ClickActivity'" class="form-inline">-->
  <!--    <select [(ngModel)]="selectedPreset" class="form-control d-inline-block my-2 me-2 w-fit">-->
  <!--      <option [value]="undefined" disabled selected>Select a preset</option>-->
  <!--      <option *ngFor="let preset of presets" [ngValue]="preset">{{ preset.name }}</option>-->
  <!--    </select>-->
  <!--    <button (click)="onAddPreset()" class="btn btn-outline-primary" type="button">Add Preset <i class="bi bi-columns-gap"></i></button>-->
  <!--  </div>-->
</div>
