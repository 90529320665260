<div class="file-controls">
  <!-- Button trigger remove image -->
  <button
    (click)="clearFile()"
    *ngIf="currentFileMeta"
    class="btn btn-no-space"
    ngbPopover="Clear selection. Does not delete the file."
    triggers="hover"
    type="button"
  >
    <span class="bi bi-x"></span>
  </button>

  <!-- Button trigger select file modal -->
  <button
    (click)="prepareUpload(); openSelectFileModal(selectFileModal)"
    class="btn btn-no-space"
    id="select-file-button"
    ngbPopover="Select a file"
    triggers="hover"
    type="button"
  >
    <span class="bi bi-folder2-open"></span>
  </button>

  <!-- Button trigger upload modal -->
  <button
    (click)="prepareUpload(); openModal(uploadModal)"
    class="btn btn-no-space"
    id="upload-file-button"
    ngbPopover="Upload a new file"
    triggers="hover"
    type="button"
  >
    <span class="bi bi-upload"></span>
  </button>

  <!-- Button trigger replace modal -->
  <button
    (click)="openReplaceModal(uploadModal)"
    *ngIf="currentFileMeta"
    class="btn btn-no-space"
    ngbPopover="Replace or rename the file content. This will update it everywhere it is used."
    triggers="hover"
    type="button"
  >
    <span class="bi bi-recycle"></span>
  </button>

  <div (click)="uploadFileName = currentFileMeta.name; editingName = true" *ngIf="currentFileMeta && !editingName" role="none">
    {{ currentFileMeta.name }}
  </div>
  <input
    (change)="onUpdateFileName()"
    *ngIf="currentFileMeta && editingName"
    [(ngModel)]="uploadFileName"
    class="form-control"
    style="max-width: 250px"
    type="text"
  />
  <div *ngIf="currentFileMeta && (currentFileMeta.fileType === 'image/png' || currentFileMeta.fileType === 'image/jpeg')" class="ms-2">
    {{ currentFileMeta.width }} x {{ currentFileMeta.height }}
  </div>
</div>

<!-- load Modal -->
<ng-template #selectFileModal let-modal>
  <div class="modal-header" id="select-file-modal-header">
    <h5 class="modal-title" id="loadModalLabel">Select file</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="select-file-modal-close-button"
      type="button"
    ></button>
  </div>

  <div class="modal-body py-0">
    <div *ngIf="loadingFiles && !loadedFiles.length" class="text-center py-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <ng-container *ngIf="!loadingFiles || loadedFiles.length">
      <div class="d-flex py-2 sticky-top bg-body">
        <input (ngModelChange)="debouncedSearch($event)" [(ngModel)]="searchTerm" class="form-control" placeholder="Search" type="text" />

        <button (click)="this.searchTerm = ''; search()" *ngIf="searchTerm.length" class="btn btn-secondary ms-2" type="button">
          <span class="bi bi-x"></span>
        </button>
      </div>

      <div class="file-table fw-bold" style="padding-right: 24px">
        <div>File name</div>
        <div>Note</div>
        <div>File type</div>
        <div>File size</div>
        <div></div>
      </div>

      <cdk-virtual-scroll-viewport (scrolledIndexChange)="clearPopovers()" itemSize="20" style="height: 50vh">
        <div *cdkVirtualFor="let meta of searchFiles" class="file-table">
          <div
            (click)="selectFile(meta.uid)"
            (keyup)="($event.key === 'Space' || $event.key === 'Enter') && selectFile(meta.uid)"
            [ngbPopover]="preview"
            [openDelay]="750"
            [popoverContext]="{ meta: meta }"
            [style]="'cursor: default; word-break: break-all'"
            container="body"
            role="button"
            tabindex="0"
            triggers="hover"
          >
            {{ meta.name }}
          </div>
          <div [style]="'max-width:50px; cursor: default'" tabindex="-1">
            <span *ngIf="meta.note" class="bi bi-journal" ngbPopover="{{ meta.note }}" triggers="hover"> </span>
          </div>
          <div
            (click)="selectFile(meta.uid)"
            (keyup)="($event.key === 'Space' || $event.key === 'Enter') && selectFile(meta.uid)"
            [style]="'max-width:150px; cursor: default'"
            tabindex="-1"
          >
            {{ meta.fileType }}
          </div>
          <div
            (click)="selectFile(meta.uid)"
            (keyup)="($event.key === 'Space' || $event.key === 'Enter') && selectFile(meta.uid)"
            [style]="'max-width:150px; cursor: default'"
            tabindex="-1"
          >
            {{
              meta.fileSize >= 1000000
                ? (meta.fileSize / 1000000).toFixed(2) + ' MB'
                : meta.fileSize >= 1000 && meta.fileSize < 1000000
                  ? (meta.fileSize / 1000).toFixed(2) + ' kB'
                  : meta.fileSize.toFixed(2) + ' bytes'
            }}
          </div>
          <div class="d-flex align-items-center gap-2">
            <span
              (click)="openReplaceModal(uploadModal)"
              (keyup)="$event.key === 'Enter' && openReplaceModal(uploadModal)"
              class="bi bi-recycle"
              ngbPopover="Replace {{ meta.name }}"
              role="button"
              tabindex="0"
              triggers="hover"
            ></span>
            <div
              (click)="deleteFile(meta.uid, meta.name)"
              (keyup)="($event.key === 'Space' || $event.key === 'Enter') && deleteFile(meta.uid, meta.name)"
              [style]="'cursor: pointer'"
              ngbPopover="Delete {{ meta.name }}"
              role="button"
              tabindex="0"
              triggers="hover"
            >
              <span class="bi bi-trash"></span>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
</ng-template>

<!-- upload Modal -->
<ng-template #uploadModal let-modal>
  <div class="modal-header" id="upload-file-modal-header">
    <h5 class="modal-title">{{ replaceFileUid ? 'Replace' : 'Upload' }} media</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="upload-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="submitFileUpload(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" class="hidden" id="fileUpload" type="file" />

        <div class="file-upload">
          <span class="pe-2">{{ fileName || 'No file uploaded yet.' }}</span>

          <button
            (click)="fileUpload.click()"
            class="btn btn-primary btn-circle btn-xl"
            id="upload-file-from-local-storage-button"
            type="button"
          >
            <span class="bi bi-paperclip"></span>
          </button>
        </div>
      </div>
      <div *ngIf="fileName" class="form-group">
        <label for="name">Name: </label>
        <input [(ngModel)]="uploadFileName" class="form-control" id="name" name="name" placeholder="Type file name..." type="text" />
      </div>
      <div *ngIf="fileName" class="form-group">
        <label for="alt">Description: </label>
        <input [(ngModel)]="uploadFileAlt" class="form-control" id="alt" name="alt" placeholder="Type description..." type="text" />
      </div>
      <button [disabled]="uploading" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>

<ng-template #preview let-meta="meta">
  <div [ngSwitch]="true">
    <ng-container *ngSwitchCase="meta.fileType.includes('image')">
      <img [alt]="meta.alt" [src]="meta.url" width="150" />
    </ng-container>

    <ng-container *ngSwitchCase="meta.fileType.includes('video')">
      <video [src]="meta.url" autoplay controls loop muted style="width: 150px"></video>
    </ng-container>

    <ng-container *ngSwitchDefault> No preview available</ng-container>
  </div>
</ng-template>
