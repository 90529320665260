import { Component, Input, OnInit } from '@angular/core';
import { FieldType, FieldTypes, FieldValue, Resource } from '@services/entities/helpers';
import { DataInstanceRepository, EnumTypeRepository, StructTypeRepository } from '@services/repositories';
import { DataInstance } from '@services/entities';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { Logger } from '@services/utils';

@Component({
  selector: 'app-enum-instance-editor',
  templateUrl: './enum-instance-editor.component.html',
  styleUrls: ['./enum-instance-editor.component.scss'],
})
export class EnumInstanceEditorComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;

  choices: Resource[] = [];
  currentChoice: string | undefined;
  currentChoiceDataInstance: DataInstance | undefined;
  enumType = '';
  descriptions: Record<string, string> = {};

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private structTypeRepository: StructTypeRepository,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
    this.enumType = this.data.field.fieldId;

    const enumTypeId = FieldTypes.getDeepestReference(this.data.field.type);
    if (!enumTypeId) throw new Error('Enum type not found');

    // Get the enum type options
    // const enumOptions = this.dataService.getEnumTypeResource(enumTypeId).slice();
    const enumOptions = (await this.enumTypeRepository.get(enumTypeId)).options;

    await Promise.all(
      enumOptions.map(async (option) => {
        const choice = await this.structTypeRepository.get(option);
        this.choices.push(
          new Resource({
            name: choice.name,
            value: option,
          }),
        );
        this.descriptions[option] = choice.description ?? '';
      }),
    );

    // If the data value is not set, set it to the default value if any
    if (this.data.value) {
      this.currentChoiceDataInstance = await this.dataInstanceRepository.get(this.data.value);
      this.currentChoice = this.currentChoiceDataInstance.dataType ?? '';
    }
  }

  async update() {
    if (!this.data) throw new Error('Data not found');
    if (!this.currentChoice) throw new Error('No enum type selected');

    const instance = await this.dataInstanceRepository.create(this.currentChoice);
    await this.data.set(instance);

    // Delete the old instance if it exists
    if (this.currentChoiceDataInstance) {
      try {
        await this.dataInstanceRepository.delete(this.currentChoiceDataInstance, true);
      } catch (e) {
        Logger.warn('Failed to delete old instance', e);
      }
    }

    this.currentChoiceDataInstance = instance;
  }
}
