import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileEndpoints } from '@services/api';
import { GeneratedFileMeta } from '@services/types/generated';
import { AlertService } from '@services/UI-elements/alert-service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
})
export class FileInputComponent implements OnInit, OnChanges {
  @Input({ required: true }) accept!: string; // comma-separated list of accepted mime types, extensions, or wildcards. Example: 'image/*,video/*,.pdf' or '*/*'
  @Input({ required: true }) label!: string;
  @Input({ required: true }) mode!: 'create' | 'edit';
  @Input() fileToEdit!: GeneratedFileMeta | undefined; // if set, the file input will replace the file when uploading a new file (used to edit the file)
  @Input({ required: true }) appearance!: 'icon-button' | 'modal-button';
  @Input() disabled: boolean = false;
  @Output() updated = new EventEmitter<{ file: GeneratedFileMeta }>();

  file: File | undefined = undefined;

  fileName: string | undefined = undefined;
  fileAlt: string | undefined = undefined;
  isUploading = false;

  constructor(
    private modalService: NgbModal,
    private alertService: AlertService,
    private fileEndpoints: FileEndpoints,
  ) {}

  ngOnInit() {
    if (this.fileToEdit) this.updateFile(this.fileToEdit);
  }

  ngOnChanges(): void {
    if (this.fileToEdit) this.updateFile(this.fileToEdit); // this is needed to reflect changes when it's in a virtual scrolling list
  }

  private updateFile(file: GeneratedFileMeta) {
    this.fileToEdit = file;
    this.fileName = this.fileToEdit.name;
    this.fileAlt = this.fileToEdit.alt;
  }

  openModal(modal: TemplateRef<unknown>) {
    this.modalService.dismissAll('Closed before opening new modal');
    const modalRef = this.modalService.open(modal, { size: 'lg' });

    // automatically focus the input field when the modal is opened
    setTimeout(() => {
      const inputEl = document.getElementById('name-input-field') as HTMLInputElement;
      inputEl?.select();
    }, 0);

    return modalRef;
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (!this.file) return;

    this.fileName = this.file.name;
  }

  async submit(modal: NgbModalRef) {
    if (!this.fileName || this.fileName.length === 0) {
      this.alertService.error('Please enter a name for the file');
      return;
    }

    if (this.fileToEdit) {
      await this.replace(this.fileToEdit.uid);
    } else {
      if (!this.file) {
        this.alertService.error('Please select a file to upload');
        return;
      }

      await this.upload(this.file, this.fileName, this.fileAlt ?? '');
    }

    modal.dismiss();
  }

  private async upload(file: File, name: string, alt: string) {
    try {
      this.isUploading = true;

      const fileFormData = new FormData();
      fileFormData.append('file', file);
      fileFormData.append('name', name);
      fileFormData.append('alt', alt);

      const uploadedFile = await this.fileEndpoints.uploadFile(fileFormData);
      this.updated.emit({ file: uploadedFile });
    } finally {
      this.file = undefined;
      this.isUploading = false;
    }
  }

  private async replace(uid: string) {
    try {
      this.isUploading = true;

      const updateFormData = new FormData();
      if (this.file) {
        updateFormData.append('file', this.file);
      }
      if (this.fileName) {
        updateFormData.append('name', this.fileName);
      }
      updateFormData.append('alt', this.fileAlt ?? '');
      const updatedFile = await lastValueFrom(this.fileEndpoints.updateFileMeta(uid, updateFormData));
      this.updated.emit({ file: updatedFile });
    } finally {
      this.file = undefined;
      this.isUploading = false;
    }
  }
}
