import { Component, Input } from '@angular/core';
import { GeneratedFileMeta } from '@services/types/generated';

@Component({
  selector: 'app-file-detailed-preview',
  templateUrl: './file-detailed-preview.component.html',
  styleUrl: './file-detailed-preview.component.scss',
})
export class FileDetailedPreviewComponent {
  @Input({ required: true }) file!: GeneratedFileMeta | undefined;
}
