import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { DataInstance, Tag } from '@services/entities';
import { Color, debounce, fuzzySearch, Logger } from '@services/utils';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Field } from '@services/entities/helpers';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { AlertService } from '@services/UI-elements/alert-service';
import {
  getNameOfListInstance,
  getUidOfListInstance,
  isDataInstance,
  isGeneratedFileMeta,
  isVariable,
  ListInstance,
} from '@services/utils/ListInstance';
import { Sort } from '@angular/material/sort';
import { FileEndpoints } from '@services/api';
import { TagRepository } from '@services/repositories';
import { GeneratedTag } from '@services/types/generated';
import ScopeEnum = GeneratedTag.ScopeEnum;

@Component({
  selector: 'app-instances-list',
  templateUrl: './instances-list.component.html',
  styleUrls: ['./instances-list.component.scss'],
})
export class InstancesListComponent implements OnInit, OnChanges {
  @Input() allInstances: ListInstance[] = [];
  @Input() allTags: Tag[] = [];
  @Input() tagsPerInstance: Record<string, Tag[]> = {};
  @Input() fieldsToDisplay: Field[] = [];
  @Input() listType: string = '';

  @Output() updateFileNameEmitter: EventEmitter<{ uid: string; name: string }> = new EventEmitter<{
    uid: string;
    name: string;
  }>();
  @Output() updateFileAltEmitter: EventEmitter<{ uid: string; alt: string }> = new EventEmitter<{
    uid: string;
    alt: string;
  }>();
  @Output() replaceFileEmitter: EventEmitter<{
    uid: string;
    name: string;
    alt: string;
    file?: File;
  }> = new EventEmitter<{ uid: string; name: string; alt: string; file?: File }>();
  @Output() updateNoteEmitter: EventEmitter<{ uid: string; note: string }> = new EventEmitter<{ uid: string; note: string }>();

  @Output() selectInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() duplicateInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() downloadInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() deleteInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();

  @Output() tagSelectedEmitter: EventEmitter<{ tag: Tag; instanceUid: string }> = new EventEmitter<{
    tag: Tag;
    instanceUid: string;
  }>();
  @Output() bulkTagSelectedEmitter: EventEmitter<{ tag: Tag; instanceUids: string[]; isAdded: boolean }> = new EventEmitter<{
    tag: Tag;
    instanceUids: string[];
    isAdded: boolean;
  }>();

  fileMetaOperations: Record<string, { editingName: boolean; editingAlt: boolean }> = {};
  updateFileName = '';
  updateFileAlt = '';
  replaceFileName = '';
  replaceFileAlt = '';
  replaceFileUid = '';
  file?: File;
  currentNote = '';

  debouncedSearch = debounce(this.search.bind(this), 250);
  searchTerm = '';
  currentSort: Sort = { active: 'modified', direction: '' };
  filteredInstances: ListInstance[] = [];
  isTagUsedForFilter: Record<string, boolean> = {};

  newTagName = '';
  newTagColor = '';
  filteredTags: Tag[] = [];

  currentPage: number = 1;
  maxPages: number = 1;
  pageSize: string = '50';
  paginatedInstances: ListInstance[] = [];
  sortingPreferences: Record<string, Sort> = {};
  selectedInstances: ListInstance[] = [];

  protected readonly Color = Color;
  protected readonly Object = Object;
  protected readonly isGeneratedFileMeta = isGeneratedFileMeta;
  protected readonly getUidOfListInstance = getUidOfListInstance;
  protected readonly isDataInstance = isDataInstance;
  protected readonly isVariable = isVariable;
  protected readonly getNameOfListInstance = getNameOfListInstance;

  constructor(
    private modalService: NgbModal,
    private alertService: AlertService,
    private fileEndpoints: FileEndpoints,
    private tagRepository: TagRepository,
  ) {}

  ngOnInit() {
    this.pageSize = localStorage.getItem('pageSize') ?? '50';
    const urlParams = new URLSearchParams(window.location.search);
    this.searchTerm = urlParams.get('search') ?? '';
    this.filteredInstances = this.allInstances;

    this.sortingPreferences = JSON.parse(localStorage.getItem('sortingPreferences') ?? '{}');
    if (!this.sortingPreferences[this.listType]) {
      this.sortingPreferences[this.listType] = { active: 'modified', direction: '' };
    }
    this.currentSort = this.sortingPreferences[this.listType];

    this.filterInstances();

    this.allInstances.forEach((instance) => {
      if (isGeneratedFileMeta(instance)) {
        this.fileMetaOperations[instance.uid] = {
          editingName: false,
          editingAlt: false,
        };
      }
    });

    this.allTags.forEach((tag) => {
      this.isTagUsedForFilter[tag.uid] = false;
    });
    this.filteredTags = this.allTags.filter((tag) => !tag.isDefault);

    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    this.updatePaginatedInstances();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allInstances']) {
      this.allInstances.forEach((instance) => {
        if (isGeneratedFileMeta(instance)) {
          this.fileMetaOperations[instance.uid] = this.fileMetaOperations[instance.uid] ?? {
            editingName: false,
            editingAlt: false,
          };
        }
      });

      this.filteredInstances = this.allInstances;
      const selectedUids = this.selectedInstances.map((instance) => getUidOfListInstance(instance));
      this.selectedInstances = this.filteredInstances.filter((instance) => selectedUids.includes(getUidOfListInstance(instance)));
      this.maxPages = Math.ceil(
        this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
      );
      this.filterInstances();
    }
  }

  onPageChange(newPage: number) {
    if (newPage < 1 || newPage > this.maxPages) return;
    this.currentPage = newPage;
    this.updatePaginatedInstances();
  }

  onChangePageSize() {
    localStorage.setItem('pageSize', this.pageSize);
    this.updatePaginatedInstances();
  }

  search(event?: string) {
    this.searchTerm = event ?? '';

    // remove the previous entry in the browser history
    window.history.replaceState({}, '', window.location.pathname + '?search=' + this.searchTerm);

    this.filterInstances();
  }

  sort(sort: Sort) {
    this.sortingPreferences[this.listType] = sort;
    localStorage.setItem('sortingPreferences', JSON.stringify(this.sortingPreferences));
    this.currentSort = sort;
    this.filterInstances();
  }

  filterInstances() {
    const searchedInstances = fuzzySearch(this.searchTerm, this.allInstances, 0.75, (instance) => {
      return getNameOfListInstance(instance);
    });

    if (Object.values(this.isTagUsedForFilter).every((tagUsed) => !tagUsed)) {
      this.filteredInstances = searchedInstances;
    } else {
      this.filteredInstances = searchedInstances.filter((instance) =>
        instance.tags.some((tagOfInstance) => this.isTagUsedForFilter[tagOfInstance.uid]),
      );
    }

    if (this.currentSort.active && this.currentSort.direction !== '') {
      this.filteredInstances = this.filteredInstances.sort((a, b) => {
        const isAsc = this.currentSort.direction === 'asc';
        switch (this.currentSort.active) {
          case 'name':
            if ((isGeneratedFileMeta(a) && isGeneratedFileMeta(b)) || (isVariable(a) && isVariable(b))) {
              return this.sortInstances(a.name, b.name, isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, this.currentSort);
            }
            return 0;
          case 'modified':
            return this.sortInstances(a.modified, b.modified, isAsc);
          case 'alt':
            if (isGeneratedFileMeta(a) && isGeneratedFileMeta(b)) {
              return this.sortInstances(a.alt ?? '', b.alt ?? '', isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, this.currentSort);
            }
            return 0;
          case 'description':
            if (isVariable(a) && isVariable(b)) {
              return this.sortInstances(a.description ?? '', b.description ?? '', isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, this.currentSort);
            }
            return 0;
          case 'note':
            if (isGeneratedFileMeta(a) && isGeneratedFileMeta(b)) {
              return this.sortInstances(a.note ?? '', b.note ?? '', isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, this.currentSort);
            }
            return 0;
          case 'enumInstanceName':
            if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortInstances(a.getName(), b.getName(), isAsc);
            }
            return 0;
          case 'enumInstanceStructType':
            if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortInstances(a.structType.typeId, b.structType.typeId, isAsc);
            }
            return 0;
          case 'playthroughStatistic':
            if (isVariable(a) && isVariable(b)) {
              return this.sortInstances(String(a.isPlayThroughStatistic), String(b.isPlayThroughStatistic), isAsc);
            }
            return 0;
          default:
            if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, this.currentSort);
            }
            return 0;
        }
      });
    }

    this.selectedInstances = this.selectedInstances.filter((instance) => this.filteredInstances.includes(instance));

    this.currentPage = 1;
    this.updatePaginatedInstances();
  }

  filterTags(filterValue: string) {
    if (!filterValue) {
      this.filteredTags = this.allTags.filter((tag) => !tag.isDefault);
      return;
    }
    this.filteredTags = this.allTags.filter((tag) => tag.name.toLowerCase().includes(filterValue.toLowerCase()) && !tag.isDefault);
  }

  async onAddTag() {
    if (!this.newTagName) return;

    if (this.allTags.some((tag) => tag.name === this.newTagName)) {
      this.alertService.showAlert('Tag with name ' + this.newTagName + ' already exists', BootstrapClass.DANGER);
      return;
    }

    if (!this.newTagColor) this.newTagColor = Color.getRandomColor();

    const tag = await this.tagRepository.create({
      name: this.newTagName,
      color: this.newTagColor,
      uid: '_',
      scope: ScopeEnum.Instance,
    });

    this.onTagSelected({ tag: tag, isAdded: true }, undefined, true);

    this.newTagName = '';
    this.newTagColor = '';
  }

  onFilterTable(tag: Tag) {
    this.isTagUsedForFilter[tag.uid] = !this.isTagUsedForFilter[tag.uid];
    this.filterInstances();
  }

  onFilterAll() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = true;
    });
    this.filterInstances();
  }

  onFilterNone() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = false;
    });
    const searchedMedia = fuzzySearch(this.searchTerm, this.allInstances, 0.75, (instance) => {
      return getNameOfListInstance(instance);
    });
    this.filteredInstances = searchedMedia.filter((instance) => instance.tags.length === 0);
    this.currentPage = 1;
    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    this.updatePaginatedInstances();
  }

  onClearFilters() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = false;
    });
    this.filterInstances();
  }

  getFieldDisplayValue(instance: ListInstance, field: Field): string | undefined {
    if (!isDataInstance(instance)) return undefined;
    return instance.fieldValues[field.fieldId]?.value as string | undefined;
  }

  onUpdateFileName(mediaUid: string) {
    this.updateFileNameEmitter.emit({ uid: mediaUid, name: this.updateFileName });
    this.fileMetaOperations[mediaUid].editingName = false;
  }

  onUpdateFileAlt(mediaUid: string) {
    this.updateFileAltEmitter.emit({ uid: mediaUid, alt: this.updateFileAlt });
    this.fileMetaOperations[mediaUid].editingAlt = false;
  }

  onSubmitFileReplace(modal: NgbModalRef) {
    if (!this.replaceFileUid) {
      return;
    }

    if (!this.replaceFileName) {
      window.alert('Please enter a name for the file');
      return;
    }

    this.replaceFileEmitter.emit({
      uid: this.replaceFileUid,
      name: this.replaceFileName,
      alt: this.replaceFileAlt,
      file: this.file,
    });

    modal.dismiss();

    this.file = undefined;
    this.replaceFileUid = '';
    this.replaceFileName = '';
    this.replaceFileAlt = '';
    return;
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (!this.file) {
      Logger.error('No file selected');
      return;
    }
    this.replaceFileName = this.file.name;
    this.replaceFileAlt = '';
  }

  onOpenReplaceModal(content: TemplateRef<unknown>, instance: ListInstance): NgbModalRef | undefined {
    if (!isGeneratedFileMeta(instance)) return;
    this.modalService.dismissAll('Closed before opening new modal');
    this.replaceFileName = instance.name;
    this.replaceFileAlt = instance.alt ?? '';
    this.replaceFileUid = instance.uid;
    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'replace-modal-title', size: 'lg' });

    // automatically open file upload window
    document.getElementById('fileUpload')?.click();

    return modalRef;
  }

  onOpenNoteModal(content: TemplateRef<unknown>, instance: ListInstance): NgbModalRef | undefined {
    if (!isGeneratedFileMeta(instance)) return;
    this.modalService.dismissAll('Closed before opening new modal');
    this.currentNote = instance.note ?? '';
    this.replaceFileUid = instance.uid;
    return this.modalService.open(content, { ariaLabelledBy: 'note-modal-title', size: 'lg' });
  }

  onSaveNote(modal: NgbModalRef) {
    this.updateNoteEmitter.emit({ uid: this.replaceFileUid, note: this.currentNote });
    modal.dismiss();
    this.currentNote = '';
    this.replaceFileUid = '';
  }

  onTagSelected(event: { tag: Tag; isAdded: boolean }, instance: ListInstance | undefined, isBulkSelect: boolean = false) {
    if (isBulkSelect) {
      this.bulkTagSelectedEmitter.emit({
        tag: event.tag,
        instanceUids: this.selectedInstances.map((selectedInstance) => getUidOfListInstance(selectedInstance)),
        isAdded: event.isAdded,
      });
    } else if (instance) {
      this.tagSelectedEmitter.emit({
        tag: event.tag,
        instanceUid: getUidOfListInstance(instance),
      });
    }
  }

  onDownloadMedia(instance: ListInstance) {
    if (isGeneratedFileMeta(instance)) {
      this.fileEndpoints.downloadFile(instance.uid).subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = instance.name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

  onClickOnInstance(instance: ListInstance) {
    this.selectInstanceEmitter.emit(instance);
  }

  onDuplicateInstance(instance: ListInstance) {
    this.duplicateInstanceEmitter.emit(instance);
  }

  onDownloadInstance(instance: ListInstance) {
    this.downloadInstanceEmitter.emit(instance);
  }

  async onCopyIdToClipboard(instance: ListInstance) {
    const id = isDataInstance(instance) ? await instance.identifier : getUidOfListInstance(instance);
    navigator.clipboard
      .writeText(id)
      .then(() => {
        Logger.info('Instance Uid copied to clipboard: ' + id);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy instance Uid', BootstrapClass.DANGER);
      });
  }

  onDeleteInstance(instance: ListInstance) {
    this.deleteInstanceEmitter.emit(instance);
  }

  onSelectAllInstances() {
    if (this.selectedInstances.length === this.filteredInstances.length) {
      this.selectedInstances = [];
    } else {
      this.selectedInstances = this.filteredInstances;
    }
  }

  onSelectInstance(instance: ListInstance) {
    if (this.selectedInstances.includes(instance)) {
      this.selectedInstances = this.selectedInstances.filter((selectedInstance) => selectedInstance !== instance);
    } else {
      this.selectedInstances.push(instance);
    }
  }

  private updatePaginatedInstances() {
    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    if (this.currentPage > this.maxPages) this.currentPage = this.maxPages;
    const start = (this.currentPage - 1) * (this.pageSize === 'all' ? 0 : Number(this.pageSize));
    const end = start + (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize));
    this.paginatedInstances = this.filteredInstances.slice(start, end);
  }

  private sortFieldOfDataInstance(a: DataInstance, b: DataInstance, sort: Sort): number {
    const fieldA = a.fieldValues[sort.active];
    const fieldB = b.fieldValues[sort.active];
    if (fieldA && fieldB) {
      return this.sortInstances(fieldA.value, fieldB.value, sort.direction === 'asc');
    }
    return 0;
  }

  private sortInstances(a: string, b: string, isAsc: boolean): number {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
