<ng-container
  *ngTemplateOutlet="
    editorType === FieldEditorType.SeamlessInline
      ? seamlessInlineTemplate
      : editorType === FieldEditorType.Hidden
        ? hiddenTemplate
        : template
  "
>
</ng-container>

<ng-template #seamlessInlineTemplate>
  <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
  <ng-content></ng-content>
</ng-template>

<ng-template #hiddenTemplate>
  <div></div>
</ng-template>

<ng-template #template>
  <div class="mode-container">
    <div class="input-group" id="{{ data.field.fieldId }}-field">
      <div class="d-flex" id="{{ data.field.fieldId }}-field-label">
        <div class="mode-normal input-group-prepend h-100">
          <span
            [ngStyle]="{ 'text-decoration-line': this.data.field.deprecated ? 'line-through' : 'none' }"
            class="input-group-text gap-1 rounded-0"
          >
            <span class="text">{{ name }}</span>
            <i
              *ngIf="fieldValidationWarning"
              [ngbPopover]="fieldValidationWarning"
              class="bi bi-exclamation-triangle-fill text-warning"
              container="body"
              triggers="hover"
            ></i>
            <i *ngIf="description.length > 0" [ngbPopover]="description" class="bi bi-info-circle" container="body" triggers="hover"></i>
          </span>
        </div>
      </div>

      <div class="form-control align-content-center p-0 rounded-0">
        <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
