import { Component, Input } from '@angular/core';
import { GeneratedFileMeta } from '@services/types/generated';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrl: './file-preview.component.scss',
})
export class FilePreviewComponent {
  @Input({ required: true }) file!: GeneratedFileMeta;
  @Input() size: number = 32;

  getImageUrlForWidth(file: GeneratedFileMeta, width: number) {
    const url = new URL(file.url);
    url.searchParams.set('width', `${width}`);
    return url.toString();
  }
}
