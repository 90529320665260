<div class="container pb-2 mt-2">
  <div class="d-flex gap-1 mt-2 flex-wrap">
    <button (click)="onClearFilters()" class="badge border-0 bg-primary">Remove filters</button>
    <button (click)="onFilterAll()" class="badge border-0 bg-primary">All tags</button>
    <button (click)="onFilterNone()" class="badge border-0 bg-primary">No tags</button>
    <div
      (click)="onFilterTable(tag)"
      *ngFor="let tag of allTags"
      [ngStyle]="{
        'background-color': isTagUsedForFilter[tag.uid] ? tag.color : 'var(--bs-gray)',
        border: 'none',
        color: isTagUsedForFilter[tag.uid] ? tag.contrastColor : 'white',
        height: '28px',
      }"
      class="badge align-content-center ms-1 schema-tag cursor-pointer"
      id="filter-tag-{{ tag.name }}"
      role="none"
    >
      {{ tag.name }}
    </div>
  </div>
  <div class="d-flex py-2 gap-1">
    <input
      (ngModelChange)="debouncedSearch($event)"
      [(ngModel)]="searchTerm"
      class="form-control"
      id="search-input"
      placeholder="Search"
      type="text"
    />

    <button (click)="this.searchTerm = ''; search()" *ngIf="searchTerm.length" class="btn btn-secondary" type="button">
      <span class="bi bi-x"></span>
    </button>

    <button
      *ngIf="selectedInstances.length > 0"
      [matMenuTriggerFor]="tagMenu"
      class="btn btn-secondary"
      ngbPopover="Add tag to selected"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-tag"></span>
    </button>
    <mat-menu #tagMenu="matMenu" (closed)="newTagName = ''; filterTags('')">
      <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" class="d-flex mb-1 mx-1" role="none">
        <input
          (ngModelChange)="filterTags($event)"
          [(ngModel)]="newTagName"
          [maxLength]="25"
          class="form-control"
          placeholder="Search/Create tag"
          type="text"
        />
        <input [(ngModel)]="newTagColor" class="cursor-pointer my-auto mx-1" type="color" />
        <button (click)="onAddTag()" [disabled]="!newTagName" class="btn btn-primary"><i class="bi-plus"></i></button>
      </div>
      <div *ngFor="let tag of filteredTags" class="mb-1 ms-1">
        <button
          (click)="onTagSelected({ tag: tag, isAdded: true }, undefined, true)"
          [ngStyle]="{ 'background-color': tag.color, color: tag.contrastColor, height: '28px' }"
          class="badge border-0"
        >
          {{ tag.name }}
        </button>
      </div>
    </mat-menu>
    <button
      (click)="onPageChange(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="btn btn-secondary"
      ngbPopover="Previous page"
      triggers="hover"
    >
      <span class="bi bi-arrow-left"></span>
    </button>
    <button
      (click)="onPageChange(currentPage + 1)"
      [disabled]="currentPage === maxPages"
      class="btn btn-secondary"
      ngbPopover="Next page"
      triggers="hover"
    >
      <span class="bi bi-arrow-right"></span>
    </button>
    <select (change)="onChangePageSize()" [(ngModel)]="pageSize" class="form-select" id="paginated-size-select" style="max-width: 100px">
      <option *ngFor="let size of ['25', '50', '75', '100', 'all']" [selected]="size === pageSize" [value]="size">{{ size }}</option>
    </select>
  </div>
  <table
    (matSortChange)="sort($event)"
    [matSortActive]="this.sortingPreferences[this.listType].active"
    [matSortDirection]="this.sortingPreferences[this.listType].direction"
    class="table table-striped table-hover"
    matSort
  >
    <thead>
      <tr>
        <th>
          <input
            (change)="onSelectAllInstances()"
            [checked]="selectedInstances.length === filteredInstances.length"
            class="form-check-input"
            type="checkbox"
          />
        </th>
        <!-- columns for media-list -->
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" mat-sort-header="name" scope="col">Name</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" mat-sort-header="alt" scope="col">Alt text</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" scope="col" style="text-align: center">Preview</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" mat-sort-header="note" scope="col">Note</th>

        <!-- columns for resource-list -->
        <th *ngFor="let field of fieldsToDisplay" mat-sort-header="{{ field.fieldId }}" scope="col">{{ field.name }}</th>
        <th *ngIf="isDataInstance(allInstances[0]) && fieldsToDisplay.length === 0" mat-sort-header="enumInstanceName" scope="col">Name</th>
        <th *ngIf="isDataInstance(allInstances[0]) && fieldsToDisplay.length === 0" mat-sort-header="enumInstanceStructType" scope="col">
          Type
        </th>

        <!-- columns for variable-list -->
        <th *ngIf="isVariable(allInstances[0])" mat-sort-header="name" scope="col">Name</th>
        <th *ngIf="isVariable(allInstances[0])" mat-sort-header="description" scope="col">Description</th>
        <th *ngIf="isVariable(allInstances[0])" mat-sort-header="playthroughStatistic" scope="col">Is play through statistic</th>

        <!-- columns for all the lists -->
        <th mat-sort-header="modified" scope="col">Modified</th>
        <th scope="col">Tags</th>
        <th scope="col" style="width: 240px">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let instance of paginatedInstances">
        <td class="align-content-center">
          <input
            (change)="onSelectInstance(instance)"
            [checked]="selectedInstances.includes(instance)"
            class="form-check-input"
            type="checkbox"
          />
        </td>
        <!-- columns for media-list -->
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <div
            (click)="updateFileName = instance.name; fileMetaOperations[instance.uid].editingName = true"
            *ngIf="!fileMetaOperations[instance.uid].editingName"
            role="none"
          >
            {{ instance.name }}
          </div>
          <input
            (change)="onUpdateFileName(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingName"
            [(ngModel)]="updateFileName"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <div
            (click)="updateFileAlt = instance.alt; fileMetaOperations[instance.uid].editingAlt = true"
            *ngIf="!fileMetaOperations[instance.uid].editingAlt"
            role="none"
          >
            {{ instance.alt }}
          </div>
          <input
            (change)="onUpdateFileAlt(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingAlt"
            [(ngModel)]="updateFileAlt"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center" style="place-items: center">
          <app-file-preview [size]="32" [file]="instance" style="display: block" />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <button
            (click)="onOpenNoteModal(noteModal, instance)"
            *ngIf="instance.note"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="{{ instance.note }}"
            triggers="hover"
          >
            <i class="bi bi-journal action-button"></i>
          </button>
          <button
            (click)="onOpenNoteModal(noteModal, instance)"
            *ngIf="!instance.note"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Add note"
            triggers="hover"
          >
            <i class="bi bi-plus-circle action-button"></i>
          </button>
        </td>

        <!-- columns for variable-list -->
        <td (click)="onClickOnInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer align-content-center">
          {{ instance.name }}
        </td>
        <td (click)="onClickOnInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer align-content-center">
          {{ instance.description }}
        </td>
        <td (click)="onClickOnInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer align-content-center">
          {{ instance.isPlayThroughStatistic ? 'Yes' : 'No' }}
        </td>

        <!-- columns for resource-list -->
        <td (click)="onClickOnInstance(instance)" *ngFor="let field of fieldsToDisplay" class="cursor-pointer align-content-center">
          {{ getFieldDisplayValue(instance, field) }}
        </td>
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isDataInstance(instance) && fieldsToDisplay.length === 0"
          class="cursor-pointer align-content-center"
        >
          {{ instance.getName() }}
        </td>
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isDataInstance(instance) && fieldsToDisplay.length === 0"
          class="cursor-pointer align-content-center"
        >
          {{ instance.structType.typeId }}
        </td>

        <!-- columns for all the lists -->
        <td (click)="onClickOnInstance(instance)" class="align-content-center">{{ instance.modified | date: 'medium' }}</td>
        <td class="align-content-center">
          <app-tags
            (tagSelected)="onTagSelected($event, instance)"
            [allTags]="allTags"
            [tags]="tagsPerInstance[getUidOfListInstance(instance)]"
          />
        </td>
        <td class="align-content-center" style="width: 240px">
          <div class="d-flex gap-1 justify-content-end">
            <!-- buttons for the media-list -->
            <button
              (click)="onOpenReplaceModal(replaceModal, instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Replace or rename the file content. This will update it everywhere it is used."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-recycle action-button"></span>
            </button>
            <button
              (click)="onClickOnInstance(instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="View the usages of this media."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-search action-button"></span>
            </button>
            <button
              (click)="onDownloadMedia(instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              ngbPopover="Download media"
              triggers="hover"
            >
              <i class="bi bi-download action-button"></i>
            </button>

            <!-- button for both resource- and variable-list -->
            <button
              (click)="onClickOnInstance(instance)"
              *ngIf="isDataInstance(instance) || isVariable(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              id="open-instance"
              ngbPopover="Edit {{ getNameOfListInstance(instance) }}"
              triggers="hover"
            >
              <span class="bi bi-pencil-square action-button"></span>
            </button>

            <!-- buttons for the resource-list -->
            <button
              (click)="onDuplicateInstance(instance)"
              *ngIf="isDataInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="duplicate-instance"
              ngbPopover="Duplicate the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-copy action-button"></span>
            </button>
            <button
              (click)="onDownloadInstance(instance)"
              *ngIf="isDataInstance(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              ngbPopover="Download JSON"
              triggers="hover"
            >
              <i class="bi bi-download action-button"></i>
            </button>

            <!-- buttons for all the lists -->
            <button
              (click)="onCopyIdToClipboard(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Copy the Uid of the instance to the clipboard."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-code action-button"></span>
            </button>
            <button
              (click)="onDeleteInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="delete-instance"
              ngbPopover="Permanently delete the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-trash action-button"></span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex pb-2 float-end">
    <button
      (click)="onPageChange(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="btn btn-secondary ms-1"
      ngbPopover="Previous page"
      triggers="hover"
    >
      <span class="bi bi-arrow-left"></span>
    </button>
    <button
      (click)="onPageChange(currentPage + 1)"
      [disabled]="currentPage === maxPages"
      class="btn btn-secondary ms-1"
      ngbPopover="Next page"
      triggers="hover"
    >
      <span class="bi bi-arrow-right"></span>
    </button>
    <select
      (change)="onChangePageSize()"
      [(ngModel)]="pageSize"
      class="form-select ms-1"
      id="paginated-size-select2"
      style="max-width: 100px"
    >
      <option *ngFor="let size of ['25', '50', '75', '100', 'all']" [selected]="size === pageSize" [value]="size">{{ size }}</option>
    </select>
  </div>
</div>

<ng-template #replaceModal let-modal>
  <div class="modal-header" id="replace-file-modal-header">
    <h5 class="modal-title">Replace media</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="replace-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="onSubmitFileReplace(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" class="hidden" id="fileUpload" type="file" />

        <div class="file-upload">
          {{ replaceFileName || 'No file uploaded yet.' }}

          <button
            (click)="fileUpload.click()"
            class="btn btn-primary btn-circle btn-xl"
            id="upload-file-from-local-storage-button"
            type="button"
          >
            <span class="bi bi-paperclip"></span>
          </button>
        </div>
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="name">Name: </label>
        <input [(ngModel)]="replaceFileName" class="form-control" id="name" name="name" placeholder="Type name..." type="text" />
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="alt">Description: </label>
        <input [(ngModel)]="replaceFileAlt" class="form-control" id="alt" name="alt" placeholder="Type description..." type="text" />
      </div>
      <button [disabled]="!replaceFileUid" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>

<ng-template #noteModal let-modal>
  <div class="modal-header" id="note-modal-header">
    <h5 class="modal-title">Note</h5>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" id="note-modal-close-button" type="button"></button>
  </div>
  <div class="modal-body">
    <form (submit)="onSaveNote(modal)">
      <div class="form-group">
        <textarea
          [(ngModel)]="currentNote"
          [ngModelOptions]="{ standalone: true }"
          class="form-control"
          id="note"
          placeholder="Leave a note"
          type="text"
        >
        </textarea>
      </div>

      <button [disabled]="!replaceFileUid" class="btn btn-primary btn-space" type="submit">Save</button>
    </form>
  </div>
</ng-template>
