<div class="position-relative">
  <div>
    <app-flowchart
      (addKennisNode)="onAddKennisNode($event)"
      (nodeClicked)="setSelectedNode($event)"
      [flowchartType]="FlowchartType.MODULE"
      [nodes]="nodes"
    />
  </div>

  <div class="d-flex position-absolute top-0">
    <div class="bg-body d-flex flex-column">
      <span [ngStyle]="{ 'font-size': 'small' }" class="pt-1 ps-3 pe-1">Module: {{ moduleName }}</span>
    </div>

    <button (click)="openOverview()" [ngStyle]="{ 'font-size': 'small' }" class="btn btn-primary btn-sm">
      <i class="bi bi-info-circle"></i> Info
    </button>
  </div>

  <div class="position-absolute top-0 end-0">
    <a
      *ngIf="environment.playGameUrl && moduleId"
      class="btn btn-primary btn-sm"
      href="{{ environment.playGameUrl }}?offline=1&module={{ moduleId }}"
      target="_blank"
    >
      <i class="bi bi-play-circle"></i>
      Play
    </a>
  </div>

  <div class="container mt-2 pb-2">
    <app-flowchart-node (deleteNode)="deleteKennisNode($event)" *ngIf="currentKennisNode" [node]="currentKennisNode" />

    <app-struct-instance-editor *ngIf="module && !currentKennisNode" [data]="module" />
  </div>
</div>
